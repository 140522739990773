import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "projekte/die_zukunft_finden/image1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(
      relativePath: { eq: "projekte/die_zukunft_finden/image2.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(
      relativePath: { eq: "projekte/die_zukunft_finden/image3.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(
      relativePath: { eq: "projekte/die_zukunft_finden/image4.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto">
          <div className="flex flex-col">
            <GatsbyImage
              className="my-8 md:my-0 w-full md:w-1/3 z-5 self-end"
              objectFit="cover"
              image={data.image2.childImageSharp.gatsbyImageData}
              alt="Max Thinius Portrait"
            />
            <GatsbyImage
              className=" my-8 md:my-0 md:-mt-40 lg:-mt-92 max-w-672 w-full  "
              objectFit="contain"
              image={data.image3.childImageSharp.gatsbyImageData}
              alt="Auf der Suche nach der Zukunft Mindmap"
            />
          </div>
          <div className="flex-1 p-32 mb-16">
            <h3>
              Mit dem Futurologen Max Thinius „Auf der Suche nach der Zukunft!“
            </h3>
            <p>
              <b>Max Thinius</b> gilt als Europas führender Futurologe und
              Zukunftsgestalter. Neben seiner Arbeit als Berater für Menschen,
              Unternehmen und Regionen, ist er Moderator und Bestsellerautor. Er
              redet und schreibt über Digitalisierung, Zukunft,
              gesellschaftliche Entwicklung, Handel, Politik, Bildung,
              Lebensqualität, kurzum eine vernetzende generalistische Sicht über
              alle Aspekte in unserer Gesellschaft, und wie sich darin unser
              Alltag verändern wird.
            </p>
            <div className="flex flex-col md:flex-row">
              <GatsbyImage
                className="my-32 w-full md:w-1/2 mr-16"
                objectFit="contain"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Max Thinius Portrait"
              />
              <GatsbyImage
                className="my-32 w-full md:w-1/2"
                objectFit="contain"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Auf der Suche nach der Zukunft Lüdenscheid"
              />
            </div>
            <p className="pb-16">
              <b>Max Thinius</b> begleitet das Projekt „Wunderkammer der
              Zukunft“ von Beginn an und war bereits zweimal zu Gast in
              Lüdenscheid. In der You-Tube-Serie{' '}
              <b>„Auf der Suche nach der Zukunft“</b> spricht er mit
              Experten*innen über verschiedene Themen, die uns jetzt und in der
              Zukunft bewegen.
            </p>
            <div className="flex flex-col my-32">
              <p className="mb-8 ">
                <b>Freiheit, Sicherheit, Verantwortung:</b>
                <br /> Im Gespräch mit der Regiesseurin und Autorin Anja von
                Kampen und dem Kinder- und Comicphilosoph Knietzsche
              </p>
              <iframe
                title="Auf der Suche nach der Zukunft! – Freiheit, Sicherheit, Verantwortung"
                className="h-256 lg:h-512"
                frameBorder="0"
                allow="fullscreen; display-capture; autoplay"
                src="https://www.youtube.com/embed/KWhIB0K-SR8"
                width="100%"
              />
              <p className="mb-8 pt-64">
                <b>Utopien, Fortschritt, Digitalisierung:</b>
                <br /> Im Gespräch mit der Zukunftsforscherin und Gründerin
                Aileen Moeck
              </p>
              <iframe
                title=" Auf der Suche nach der Zukunft! – Utopien, Fortschritt, Digitalisierung"
                className="h-256 lg:h-512"
                frameBorder="0"
                allow="fullscreen; display-capture; autoplay"
                src="https://www.youtube.com/embed/1w9xqWpkQkw"
                width="100%"
              />
              <p className="mb-8 pt-64">
                <b>Partnerschaft, Familie, Leben & Arbeiten:</b>
                <br /> Im Gespräch mit der Schauspielerin und Sprecherin Mareike
                Fell
              </p>
              <iframe
                title="Auf der Suche nach der Zukunft! – Partnerschaft, Familie, Leben & Arbeiten"
                className="h-256 lg:h-512"
                frameBorder="0"
                allow="fullscreen; display-capture; autoplay"
                src="https://www.youtube.com/embed/cZs1vn99qoY"
                width="100%"
              />
              <p className="mb-8 pt-64">
                <b>Miteinander, Gleichsam & Teilhabe:</b>
                <br /> Im Gespräch mit dem Gründer der MEMBA Real Estate,
                Okitonga-Memba
              </p>
              <iframe
                title="Auf der Suche nach der Zukunft! – Miteinander, Gleichsam & Teilhabe"
                className="h-256 lg:h-512"
                frameBorder="0"
                allow="fullscreen; display-capture; autoplay"
                src="https://www.youtube.com/embed/J7-5x2ZcL7s"
                width="100%"
              />
            </div>

            <b className="flex flex-col">
              <a
                href="https://maxthinius.de"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zur Webseite des Künstlers:
              </a>
            </b>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Die Zukunft finden</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Max Thinius gilt als Europas führender Futurologe und
      Zukunftsgestalter. Neben seiner Arbeit als Berater für Menschen,
      Unternehmen und Regionen, ist er Moderator und Bestsellerautor. Er
      redet und schreibt über Digitalisierung, Zukunft,
      gesellschaftliche Entwicklung, Handel, Politik, Bildung,
      Lebensqualität, kurzum eine vernetzende generalistische Sicht über
      alle Aspekte in unserer Gesellschaft, und wie sich darin unser
      Alltag verändern wird."
      publicURL={props.data.image2.publicURL}
      title="Die Zukunft finden"
    />
  );
};

export default Page;
