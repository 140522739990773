import { RouteEnum } from '@model/route.enum';

export const navigationItemsProjects = [
  { title: 'Der Gelbe', to: RouteEnum.PROJECTS_12 },
  { title: 'Die Zukunft finden', to: RouteEnum.PROJECTS_11 },
  { title: 'Kiss the cook!', to: RouteEnum.PROJECTS_9 },
  { title: 'Silver Linings', to: RouteEnum.PROJECTS_10 },
  { title: 'Fensterprosa', to: RouteEnum.PROJECTS_6 },
  { title: '1 Min Audiovisual Joy', to: RouteEnum.PROJECTS_7 },
  { title: 'Yonder', to: RouteEnum.PROJECTS_8 },
  { title: 'Reset the Forest', to: RouteEnum.PROJECTS_3 },
  { title: 'Wortfindungsamt', to: RouteEnum.PROJECTS_4 },
  { title: 'Wunder Welle', to: RouteEnum.PROJECTS_5 },
  { title: 'Wunderkammer 360°', to: RouteEnum.PROJECTS_1 },
  { title: 'Hybrid11', to: RouteEnum.PROJECTS_2 },
];
